<template>
  <div>
    <b-row>
      <b-col lg="6" md="6" sm="6" class="heading-title">
        <h4>Child Venue</h4>
        <b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
          <b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
          <b-breadcrumb-item to="/venues">Parent Venue</b-breadcrumb-item>
          <b-breadcrumb-item active>Child Venue</b-breadcrumb-item>
        </b-breadcrumb>
      </b-col>
      <b-col lg="6" md="6" sm="6" class="btns-header"> </b-col>
    </b-row>
    <br />
    <b-card class="mb-4 w-100">
      <div class="venue-heading">
        <b-row>
          <b-col
            class="d-flex align-items-stretch"
            cols="12"
            lg="3"
            md="6"
            sm="12"
          >
            <div class="w-100 p-2">
              <!-- <multiselect
                v-model="venue_selected"
                :options="venue_option"
                placeholder="Select Venue"
              >
              </multiselect> -->
            </div>
          </b-col>
          <b-col
            class="d-flex align-items-stretch"
            cols="12"
            lg="3"
            md="6"
            sm="12"
          >
            <div class="w-100 p-2">
              <div>
                <!--  -->
              </div>
            </div>
          </b-col>
          <b-col
            class="d-flex align-items-stretch"
            cols="12"
            lg="6"
            md="12"
            sm="12"
          >
            <div class="w-100 d-flex justify-content-end">
               <div class="p-2">
               <!--  <b-button class="btn-venuecolor"
                  variant="primary"
                  @click="CreateChildVanue()"
                >
                  <i class="mdi mdi-plus"></i>&nbsp;Child Venue</b-button
                >  -->
                <b-button class="btn-venuecolor"
                  variant="primary"
                  @click="editVenue1()"
                >
                  <i class="mdi mdi-plus"></i>&nbsp;Child Venue</b-button
                >
              </div>
              <!--  <div class="p-2">
                <b-button variant="primary" @click="applyFilter()">
                  Apply</b-button
                >
              </div>
              <div class="p-2">
                <b-button variant="primary" @click="resetFilter()">
                  Reset</b-button
                >
              </div> -->
              <div class="p-2">
                <b-button class="btn-venuecolor"
                  id="button_width"
                  @click="export_venue()"
                  variant="primary"
                >
                  <i class="mdi mdi-export"></i>Export</b-button
                >
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

     <!-- <div>
        <b-row>
          <b-col
            class="d-flex align-items-stretch"
            cols="12"
            lg="4"
            md="6"
            sm="6"
          >
            <div
              class="
                w-100
                justify-content-sm-center
                justify-content-xl-start
                justify-content-md-start
              "
            >
              <div class="p-2">
                <b-form-select
                  id="select_perpage"
                  v-model="pageSize"
                  :options="options"
                  @change="handlePageSizeChange($event)"
                ></b-form-select>
              </div>
            </div>
          </b-col>

          <b-col
            class="d-flex align-items-stretch"
            cols="12"
            lg="8"
            md="12"
            sm="12"
          >
            <div class="w-100 p-2">
              <b-form-input
                v-model="search"
                id="search"
                type="text"
                placeholder="Search here"
                v-on:keyup="key_search()"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>
      </div>-->
      <br />
      <div>
        <b-table
          :busy="load"
          :fields="fields"
          :items="child_venue"
          responsive
          hover
          bordered
          show-empty
          empty-text="No Data Available"
          class="text-center"
        >
          <template #table-busy>
            <b-skeleton-table
              v-if="load"
              :rows="10"
              :columns="6"
              :hide-header="true"
              :table-props="{ bordered: true, striped: true }"
            ></b-skeleton-table>
          </template>
          <template #cell(venue_publish)="data">
            <b-form-checkbox
              name="check-button"
              switch
              v-on:change="statusChange(data.item.id)"
              :checked="data.item.venue_publish == 1"
            >
            </b-form-checkbox>
          </template>
           <template #cell(edit_venue)="data">
             <b-link @click="viewVenue(data.item.id)">
                <i class="fas fa-eye text-dark"></i>
            </b-link>
            &nbsp;
            <!--  <b-link @click="editVenue(data.item.id)">
              <i class="fas fa-edit text-primary"></i>
            </b-link> -->
             &nbsp;
              <b-link @click="editVenue2(data.item.id)">
              <i class="fas fa-edit text-primary"></i>
            </b-link> 
          </template>
        </b-table>
        <b-row align-v="center">
          <b-col cols="4">
            <span
              >Showing <b>{{ totalRows }}</b> out of
              <b>{{ totalData }}</b> entries.</span
            >
          </b-col>
          <b-col cols="8">
            <b-pagination
              v-model="page"
              :total-rows="count"
              :per-page="pageSize"
              @change="handlePageChange"
              prev-text="Prev"
              next-text="Next"
              align="right"
            ></b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
//import Multiselect from "vue-multiselect";
export default {
  name: "managevenues",

  data: () => ({
    title: "managevenues",
    num: 1,
    //parent_id1: this.$route.params.id,
    // Month Table
    fields: [
      //  'index',
      {
        key: "child_auto_id",
        label: "Child ID",
        sortable: true,
      }, 
      {
        key: "child_venue_name",
        label: "Child Venue Name",
        sortable: true,
      },
      {
        key: "Category",
        label: "Category",
        sortable: true,
      },
      {
        key: "venue_publish",
        label: "Publish",
      },
      {
        key: "edit_venue",
        label: "Action",
      },
    ],
    item: [],
    value: "",
    venue_selected: "",
    pincode_selected: "",
    child_venue: [],
    venueCounts: 0,
    options: [10, 20, 30, 50],

    pageSize: 10,
    show: false,
    load: true,
    filter: "",
    search: "",
    CurrentPage: 1,
    sortBy: "created_at",

    page: 1,
    count: 0,
    totalData: "",
    totalRows: "",

    PerPage: "50",

    venue_option: [],
  }),
  components: {/*  Multiselect */ },
  methods: {
    editVenue(id)
    {
      this.$router.push("/venues/edit-child-venue/" + id);
    },
    editVenue2(id)
    {
      this.$router.push("/venues/new_edit_child_venue/index/" + id);
    },
    editVenue1()
    {
      const id = this.$route.params.id;
      this.$router.push("/venues/Child_venue/index/" + id);
    },
     viewVenue(id)
    {
      this.$router.push("/venues/view-child-venue/" + id);
    },
    CreateChildVanue() {
      const id = this.$route.params.id;
      this.$router.push("/venues/add-child-venue/" + id);
    },
    handlePageChange(value) {
      this.page = value;
      this.load = true;
      this.ChildVenueListing();
    },
    handlePageSizeChange(event) {
      // console.log(event.target.value);
      this.pageSize = event;
      this.load = true;
      this.page = 1;
      this.ChildVenueListing();
    },
    sortChanged(e) {
      this.sortBy = e.sortBy;
      this.load = true;
      this.ChildVenueListing();
    },
    ChildVenueListing() {
      this.load = true;
      this.show = true;
      var parent_id = this.$route.params.id;
      var keyword = this.search;
      const params = this.getRequestParams(
        this.page,
        this.pageSize,
        this.sortBy
      );
      axios
        .post("/api/fetch_child_venue", {
          params,
          search_keyword: keyword,
          child_venue_name: this.venue_selected,
          parent_venue_id: parent_id,
        })
        .then((resp) => {
          if (resp.data.status_code == 200) {
            this.child_venue = [];
            if (resp.data.status) {
              this.count = this.totalData = resp.data.total_records;
              this.totalRows = resp.data.child_venues.length;

              for (var i = 0; i < resp.data.child_venues.length; i++) {
                this.child_venue.push({
                  id: resp.data.child_venues[i].child_venue_id,
                  child_auto_id: resp.data.child_venues[i].child_auto_no,
                  child_venue_name: resp.data.child_venues[i].child_venue_name,
                 // Category: resp.data.child_venues[i].child_venue_city,
                  Category: resp.data.child_venues[i].name,
                  venue_publish: resp.data.child_venues[i].publish_status,
                });
              }
              this.show = false;
              this.load = false;
            } else {
              if (resp.data.id_not_exists == true) {
                this.$router.push("/venues");
              } else {
                this.count = this.totalData = resp.data.total_records;
                this.totalRows = resp.data.child_venues.length;

                this.show = false;
                this.load = false;
              }
            }
          }
        });
    },
    getRequestParams(page, pageSize, sortBy = "created_at") {
      let params = {};
      if (page) {
        params["page"] = page - 1;
      }
      if (pageSize) {
        params["size"] = pageSize;
      }
      params["sortBy"] = sortBy;
      return params;
    },
    venueListing() {
      var parent_id = this.$route.params.id;
      axios
        .post("/api/venue_name_listing", { parent_venue_id: parent_id })
        .then((resp) => {
          if (resp.data.status_code == 200) {
            if (resp.data.status) {
              this.venue_option = resp.data.venue_names;
            }
          }
        });
    },
    applyFilter() {
      const value = "1";
      this.handlePageChange(value);
    },
    resetFilter() {
      this.pincode_selected = "";
      this.venue_selected = "";
      this.search = "";
      this.ChildVenueListing();
    },
    key_search() {
      const value = "1";
      this.handlePageChange(value);
    },
    statusChange(venue_id) {
      axios
        .post("/api/child_publish_status", { child_venue_id: venue_id })
        .then((resp) => {
          if (resp.data.status_code == 200) {
            if (resp.data.status) {
              this.$root.$refs.app.showToast("success", resp.data.message);
              //this.venuelListing();
            } else {
              this.$root.$refs.app.showToast("danger", resp.data.message);
            }
          }
        });
    },
    export_venue() {
      var parent_id = this.$route.params.id;
      var keyword = this.search;
      const params = this.getRequestParams(
        this.page,
        this.pageSize,
        this.sortBy
      );
      axios
        .post(
          "/api/child_pdf_export",
          {
            params,
            search_keyword: keyword,
            child_venue_name: this.venue_selected,
            parent_venue_id: parent_id,
          },
          {
            responseType: "blob",
          }
        )
        .then((resp) => {
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "child_venue_list.pdf");
          document.body.appendChild(link);
          link.click();
        });
    },
  },

  mounted() {
    this.ChildVenueListing();
    this.venueListing();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #2962ff;
    background-image: linear-gradient(242deg, #A44BF3 0%, #499CE8 100%);
}
</style>